#servicemid{
    background-image: url('./servicesimg/servicemid.webp '); 
    height: 80vh;
}
.services{
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 10rem;
    padding-bottom: 10rem;
    background-image: linear-gradient(to bottom, #ffffff, #c8c8d6, #9196ae, #576688, #103c64);
}

.serviceshead h1{
    text-align: center;
    font-size: 4rem;
    font-weight: 600;
    color: #103c64;
}
.serviceshead h2{
    text-align: center;
    font-size: 2vw;
    margin-top: 5rem;
    font-weight: 500;
}
.serviceshead p{
    font-size: 3vw;
    text-align: center;
    color: #103c64;
}
.servicescontent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
    margin-top: 5rem;
    /* margin-left: 8rem; */
    margin-left: 2rem;
}

.service{
    width: 270px;
    height: 270px;
    transition: .3s;
    margin-top: 7rem;

}
.service:nth-child(1){
    margin-top: 0rem;
}
.service:nth-child(2){
    margin-top: 0rem;
}
.service:nth-child(3){
    margin-top: 0rem;
}
    .service .serviceimg img{
        width: 320px;
        height: 270px;
    }
.servicecontent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 3.7rem;
    width: 304px;
    background-color: #103c64;
    transform: translateY(-35px);
    transition: .3s;
    padding: .5rem;
}
.servicecontent h3{
    padding-top: .3rem;
    text-align: center;
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: .5rem;
}
.servicecontent p{
    text-align: center;
    font-size: 1rem;
    color: skyblue;
    margin-bottom: .5rem;
    display: none;
    transition: .3s ease;
   
}
.servicecontent p {
    text-transform: lowercase;
}
.servicecontent p:first-letter {
    text-transform: uppercase;
}
.servicecontent button{
    border: 1px solid white;
    background-color: transparent;
    font-size: 1rem;
    margin-bottom: .5rem;
    cursor: pointer;
    padding-bottom: .3rem;
    display: none;
    transition: .3s ease;
    padding: .5rem;
}
.servicecontent button a{
    color:  white;
    text-decoration: none;
}

.servicecontent:hover{
    height: 11rem;
    background-color: #103c64;
    transform: translateY(-130px);
}
.servicecontent:hover  h3{
font-size: 1rem;
}

.servicecontent:hover p{
    display: block;
}
.servicecontent:hover button{
    display: block;
}

@media (width >= 1500px){
    .servicescontent{
        grid-template-columns: 1fr 1fr 1fr 1fr ;
    }
    .service:nth-child(4){
        margin-top: -0rem;
    }
}
@media (min-width: 2560px){
    .servicescontent{
        grid-template-columns: 1fr 1fr 1fr 1fr ;
    }
    .service:nth-child(4){
        margin-top: -0rem;
    }
}

/* @media only screen and (max-width: 1900px) {
.servicescontent{
    margin-left: rem;
}

} */

@media only screen and (max-width: 1024px) {
    
.servicescontent{
    row-gap: 1rem;
    column-gap: .5rem;
    margin-left: 2rem;
}
.service .serviceimg img{
    width: 316px;
}
.servicescontent{
    margin-left: .5rem;
}
.servicecontent{
    height: 5rem;
    width: 300px;
}

}

@media (max-width: 940px){
    .servicescontent{
        margin-left: 10%;
        grid-template-columns: 1fr 1fr;
    }
    .service:nth-child(3){
        margin-top: 7rem;
    }
}

@media (min-width: 425px) and (max-width: 768px) {    
    #servicemid{
        height: 60vh;
    }                         
    .services{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .serviceshead h1{
        font-size: 2.5rem;
    }
    .serviceshead h2{
        font-size: 3vw;
    }
    .serviceshead p{
        font-size: 4vw;
    }
    .service{
        margin-left: 2.5rem;
    }
.servicescontent{
    grid-template-columns: 1fr 1fr;
    row-gap: 4rem;
    column-gap: .5rem;
    margin-left: 1rem;
}
.service .serviceimg img{
    width: 200px;
}
.servicescontent{
    margin-left: .5rem;
}
.servicecontent{
    height: 12rem;
    width: 250px;
}
.servicecontent p{
    display: block;
}
.servicecontent button{
    display: block;
}
.service .serviceimg img{
    width: 250px;
    height: 200px;
}
.servicecontent{
    height: 15rem;
    width: 234px;
}   
.servicecontent:hover{
    height: 15rem;
    background-color: #103c64;
    transform: translateY(-30px);
    margin-top: 0rem;
}
.servicecontent:hover  h3{
    font-size: 1.2rem;

    }   
.service:nth-child(1) {
    margin-bottom: 8rem;
}
.service:nth-child(4) {
    margin-top: -0rem;
}
.service:nth-child(3) {
    margin-top: -0rem;
}
}

@media (max-width: 765px) {
    .service:nth-child(3) {
        margin-bottom: -8rem;
    }
    .service:nth-child(4) {
        margin-top: -0rem;
    }
}
@media (max-width: 425px) {

#servicemid{
    height: 35vh;
}
}
@media (max-width: 645px) {
.servicescontent{
    grid-template-columns: 1fr;
    margin-left: -30vw;
}
.service .serviceimg img{
    width: 416px;
    height: 350px;
}
.service{
    margin-bottom: 8rem;
}

.servicecontent{
    height: 15rem;
    width: 400px;
} 
.service:nth-child(2){
    margin-top: 7rem;
}
.service:nth-child(3){
    margin-top: 7rem;
    margin-bottom: 0rem;
}
.service:nth-child(4) {
    margin-top: 15rem;
}
}
@media (max-width: 550px){
    .servicescontent{
        grid-template-columns: 1fr;
        margin-left: -35vw;
    }
}
@media (max-width: 490px){
    .servicescontent{
        grid-template-columns: 1fr;
        margin-left: -40vw;
    }
}
@media (max-width: 460px){
    .servicescontent{
        grid-template-columns: 1fr;
        margin-left: -43vw;
    }
}

@media (width <= 425px) {
    #servicemid{
        height: 60vh;
    }                         
    .services{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .serviceshead h1{
        font-size: 2.5rem;
    }
    .serviceshead h2{
        font-size: 3vw;
    }
    .serviceshead p{
        font-size: 4vw;
    }
    .service{
        margin-left: 2.5rem;
    }
.servicescontent{
    grid-template-columns: 1fr;
    row-gap: 0rem;
}
.servicescontent{
    margin-left: -4%;
}
.servicecontent{
    height: 12rem;
    width: 250px;
}
.servicecontent p{
    display: block;
}
.servicecontent button{
    display: block;
}
.service .serviceimg img{
    width: 266px;
    height: 200px;
}
.servicecontent{
    height: 15rem;
    width: 250px;
}   
.servicecontent:hover{
    
    height: 15rem;
    background-color: #103c64;
    transform: translateY(0px);
    margin-top: 0rem;
}
.servicecontent:hover  h3{
    font-size: 1.2rem;

    }   

.service:nth-child(4) {
    margin-top: 8rem;
}
.service:nth-child(2) {
    margin-bottom: 14rem;
}
.service:nth-child(3) {
    margin-bottom: 8rem;
}
.service:nth-child(3) {
    margin-top: -0rem;
}
}
@media (max-width: 320px){
    .servicescontent{
        margin-left: -7%;
    }
}
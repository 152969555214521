* {
    margin: 0;
    padding: 0;
    /* font-family: "Cambria", sans-serif; */
}
body{
    width: 100vw;
    overflow-x: hidden;
}
/* topabout----------------- */
.aboutus {
    background-image: linear-gradient(to bottom, #ffffff, #e2e3f0, #c1c9e1, #9bb1d2, #6f9ac3);
    margin-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.aboutus .abouthead {
    margin-top: 2rem;
    font-size: 4rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: #103c64;
}

.aboutcontent {
    padding: 3rem;
    overflow: hidden;
    margin-top: 1rem;
}
.vision{
    display: flex;
}
.visionimg img{
    margin-top: 1rem;
    height: 30rem;
    width: 37.5rem;
}

.aboutcontent1 {
    display: flex;
}

.aboutjmja{
    font-size: 1.4rem;
    padding: 2rem;
    padding-top: 1rem;
    margin-bottom: 2rem;
    text-align: justify;
    line-height: 1.5;
}

.aboutcontent2 {
    display: flex;
    margin-top: 5rem;
}

.about1in h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    
}

.about1in p {
    width: 80%;
    font-size: 1.4rem;
    text-align: justify;
    margin-bottom: 2rem;
    line-height: 1.2;
}

.about1in {
    margin-left: 5rem;
}

.about2in {
    
    margin-right: 5rem;
}

.about2in h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.about2in p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.aboutfoot {
    margin-top: 1rem;
}

.aboutfoot h1 {
    font-size: 2.5rem;
    text-align: center;
}

.aboutfoot p {
    margin-top: 1rem;
    font-size: 1.2rem;
    margin-left: 3rem;
    margin-right: 3rem;
}

/*////////////////////////**/
#aboutmid {
    background-image: url('./aboutimg/aboutimage.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 80vh;
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

}
.aboutmidcontent{
    width: 60%;
}
.aboutmidcontent p:nth-child(1){
    color: white;
    font-size: 2vw;
    padding-bottom: 1rem;
    border-bottom: 3px solid #7AB65C;
  
  }
  .aboutmidcontent p:nth-child(2){
    color: white;
    font-size: 3.5vw;
  
  }
  
  #team{
      background-image: url('../team/teammid.webp');
      /* background-repeat: no-repeat; */
      /* background-position: center; */
      /* background-size: cover; */
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    


/* ------------team -------------- */
 .team{
    background-image: linear-gradient(to bottom, #ffffff, #c8c8d6, #9196ae, #576688, #103c64);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.team-row{
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10rem;
}
.title-box {
    text-align: center;
    margin: 50px auto 50px;

}

.title-box h1 {
    color: #103c64;
    font-size: 3.5rem;
    margin-top: 10px;
    line-height: 80px;
}

.title-box p {
    color: rgb(20, 26, 21);
    font-size: 3rem;
    margin-top: 10px;
    line-height: 30px;
}
.profile-box{
    height: 350px;
    width: 300px;
    margin-bottom: 8rem;
    box-shadow: 0 4px 6px 0px rgba(0, 0, 0, 0.644);
}
.profile-box img{
    height: 408px;
    width: 300px;
}
.profile-box-desc{
    width: 268px;
    height: 380px;
opacity: 0;
    transform: translateY(-355px);
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.822);
    padding: 1rem;
    color: white;
    transition: .3s ease;
    margin-top: -6.5rem;
    line-height: 1.2;
}
.profile-box:hover .profile-box-desc{
opacity: 1;
}
.profile-content{
    background-color: black;
    color: white;
    padding: .5rem;
    margin-top: -.3rem;
}
.profile-content a{
    float: right;
    margin-right: 1rem;
    margin-top: -.8rem;
}
.profile-content i{
color: skyblue;
cursor: pointer;
font-size: 1.5rem;
}


/* /////////////founder */
.founder {
    padding-top: 3rem;
    /* padding-bottom: 3rem; */
    margin-top: 5rem;
    width: 100%;
    background-image: linear-gradient(to top, #ffffff, #c8c8d6, #9196ae, #576688, #103c64);
    margin-bottom: 10rem;
}

.foundermembers {
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titlebox {
    width: 100%;
    margin-top: 2rem;
    height: auto;
    display: block;
}

.titlebox p {
    font-size: 2.5rem;
    text-align: center;
    color: white;
    margin-top: 1rem;
}

.titlebox h1 {
    font-size: 1.5rem;
    text-align: center;
    color: white;
}

.profile-card {
    position: relative;
    width: 250px;
    height: 250px;
    background-color: #fff;
    padding: 30px;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .3s;
    margin-bottom: 5rem;
}

.profile-card:nth-child(2) {
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom: 5rem;
}

.profile-card:hover .founderimg {
    transform: translateY(-80px);
}

.aboutfounder {
    margin-top: 1rem;
    display: none;
    transition: transform .3s;
    line-height: 1.5;
    text-align: justify;
}
.aboutfounder p{
font-size: 17px;
}

.profile-card:hover .aboutfounder {
    width: 500px;
    display: block;

}

.profile-card:hover {
    border-radius: 10px;
    width: 500px;
    height: 620px;
}

.profile-card .founderimg {
    position: relative;
    width: 250px;
    height: 100%;
    transition: .3s;

}

.founderimg img {
    width: 240px;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .3s;

}

.profile-card:hover .founderimg img {
    border-radius: 10px;
}

.caption {
    text-align: center;
    margin-top: 2.5rem;
    transition: margin-top 0.6s ease, text-align 0.6s ease;
    pointer-events: none;
}

.profile-card:hover .caption {
    margin-top: 0rem;
    text-align: start;
    pointer-events: unset;
}

.profile-card:hover .caption h3 {
    text-align: start;
    margin-left: 0rem;

}

.caption h3 {
    width: 20rem;
    text-align: center;
    font-size: 23px;
    margin-left: -2rem;
}

.caption p {
    font-size: 17px;
}

.caption .foundersocial {
    margin-top: .5rem;
    color: #333;
    margin-right: 8px;
    font-size: 18px;
}

.foundersocial a i {
    color: black;
    padding-right: .5rem;
    font-size: 2rem;
}

@media (max-width >= 2560px) {

.team-row{
    padding-top: 3rem;
    padding-bottom: 3rem;
    grid-template-columns:  1fr 1fr 1fr 1fr ;
}
}

@media (max-width: 1700px) {
    .team-row{
        column-gap: 3rem;
    }
}

@media only screen and (max-width: 1440px) {
    .team-row{
        column-gap: 2rem;
    }
}
@media only screen and (max-width: 1440px) {
.team{
    margin-top: 5rem;
}
}
@media only screen and (max-width: 1290px) {
   
.profile-card:hover .aboutfounder{
    width: 35vw;
    height: auto;

}
.profile-card:hover {
    height: auto;

}

}

@media only screen and (max-width: 1330px) {
    .aboutmid {
        height: 35rem;
    }

    .aboutmid::before {
        left: -1px;
        width: 99.5%;
        height: 35rem;

    }
    

    .aboutmidcontent {
        width: 60%;
    }

    .aboutmidcontent span {
        font-size: 1.2rem;
    }
    .aboutus .abouthead {
        margin-top: 1.5rem;
        font-size: 2.5rem;
    }
.visionimg img{
    display: none;
}
    .about1img img {
        height: 18rem;
    }

    .aboutcontent {
        padding: 2rem;
    }

    .about1in h1 {
        font-size: 2rem;
    }
.about1in{
    margin-left: 1rem;
    padding: 1rem;
}
    .about1in p {
        width: 100%;
        font-size: 1.4rem;
    }

    .about2img img {
        height: 18rem;
    }

    .aboutcontent {
        padding: 2rem;
    }

    .about2in h1 {
        font-size: 2rem;
    }

    .about2in p {
        width: 100%;
        font-size: 1.1rem;
    }

    .aboutfoot {
        margin-top: 1rem;
    }

    .aboutfoot h1 {
        font-size: 2.2rem;
        text-align: center;
    }

    .aboutfoot p {
        margin-top: 1rem;
        font-size: 1.1rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .profile-card {
        width: 180px;
        height: 180px;
        padding: 15px;
    }

    .profile-card:nth-child(2) {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .profile-card .founderimg {
        position: relative;
        width: 150px;
        height: 100%;
        transition: .3s;

    }

    .profile-card:nth-child(2) {
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }

    .founderimg img {
        width: 175px;
        border-radius: 50%;
        box-shadow: 0 0 22px #3336;
        transition: .3s;

    }

    .profile-card:hover .founderimg {
        transform: translateY(-60px);
    }

    .aboutfounder {
        margin-top: 1rem;
        display: none;
        transition: .3s;
    }

    .profile-card:hover .aboutfounder {
        width: 27rem;
        display: block;
        transition: .3s;

    }

    .profile-card:hover {
        border-radius: 10px;
        width: 440px;
        height: 650px;
    }

    .caption {
        width: 20rem;
        margin-left: -4rem;
    }
    
    .caption h3 {
        margin-left: 0rem;
        font-size: 20px;
    }

    .caption p {
        width: 100%;
        font-size: 16px;
    }

    .caption .foundersocial {
        margin-right: 8px;
        font-size: 18px;
    }

    .team::before {
        height: 80rem;
        
    }
    .team-row{
        margin-top: 5rem;
        grid-template-columns:  1fr 1fr 1fr;
        column-gap: 5rem;
        row-gap: 5rem;
    }

    .profile-card:hover .caption {
        margin-left: 0rem;
    }
     .title-box h1{
        font-size: 2.5rem;
     }
     .title-box p{
        font-size: 2.2rem;
     }
}
@media only screen and (max-width: 1240px) {
.team-row{
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5rem;
}
}
@media only screen and (max-width: 1084px) {
  .team-row{
    column-gap: 2rem;
  }
    .profile-card:hover{
height: 650px;
    }
    .visionimg{
        display: none;
    
    }
    .about1in{
        margin-left: 0rem;
    }


}

@media only screen and (max-width: 1010px) {

    .aboutmid {
        margin-top: 1.5rem;
        height: 30rem;
    }

    .aboutmid::before {
        left: 0;
        width: 100%;
        height: 30rem;

    }
    .aboutmidcontent {
        width: 70%;


    }
    .vision{
        display: block;
    }
    .visionimg{
        margin-left: 2rem;
    }
.visionimg img{
    height: 25rem;
    width: 42rem;
}
.vision .about1in{
    width: 100%;
    padding: 2rem;
}


    .aboutmidcontent span {
        color: skyblue;
        font-size: 1.1rem;
        margin-top: 2rem;

    }

    .aboutmidcontent p {
        color: white;
        font-size: 2rem;
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
    }


    .aboutus .abouthead {
        margin-top: 1rem;

    }

    .aboutcontent {
        padding: 1rem;
        margin-top: 2rem;
    }

    .about1in h1 {
        font-size: 1.7rem;
        margin-bottom: 1rem;

    }

    .about1in p {
        font-size: 1.4rem;
        margin-bottom: 2rem;   
    width: 94%;
    }


    .about2in {

        margin-right: 2rem;
    }

    .about2in h1 {
        font-size: 1.7rem;
    }

    .about2in p {
        font-size: 1.4rem;
    }

    .aboutcontent1 {
        display: block;
    }

    .aboutcontent2 {
        margin-top: 1rem;
        display: block;
    }

    .about1img img {
        height: 28rem;
        display: block;
        margin-left: 2rem;

    }

    .about2img img {
        display: none;

    }

    .about2in {
        display: block;
        margin-left: 2rem;
    }

    .foundermembers {
        display: block;
        margin-left: 1rem;
    }

    .profile-card {
        margin-left: 5rem;
        width: 600px;
        height: 650px;
        border-radius: 5px;
        margin-bottom: 3rem;

    }

    .aboutfounder {
        display: block;
        width: 34rem;
    }

    .profile-card:nth-child(2) {
        margin-left: 5rem;
        margin-right: 0rem;
    }

    .founderimg {
        border-radius: 10px;
        transform: translateY(-80px);
    }

    .profile-card:hover .aboutfounder {
        width: 34rem;
    }



    .founderimg img {
        border-radius: 15px;

    }

    .caption {
        text-align: start;
        margin-top: 0rem;
        margin-left: 0;
    }

    .caption h3 {
        width: 10rem;
        text-align: center;
        font-size: 23px;
        margin-left: 0rem;
    }

    .profile-card .caption h3 {
        text-align: start;
    }


    .profile-card:nth-child(2):hover h3 {
        text-align: start;

    }

 .profile-card:hover {
    width: 600px;
    height: 650px;
 }

 .profile-card:nth-child(2) {
margin-top: 5rem; 
}

    .team-row{
        margin-top: 5rem;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;
        row-gap: 5rem;
    }
    .founder{
        padding-bottom: 0rem;
        margin-bottom: 0rem;
    }
  

}

@media only screen and (max-width: 940px) {

.team-row{
    grid-template-columns: 1fr 1fr;
    column-gap: 8rem;
}
.founder{
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}
}


@media (min-width: 425px) and (max-width: 768px) {
    #team{
        height: 60vh;
    }
    #aboutmid{
        height: 60vh;
    }
.founder{
    padding-bottom: 0rem;
    margin-bottom: 0rem;
}
    .title-box {
        text-align: center;
        margin: 30px auto 50px;
    
    }

   

    .aboutmid::before {
        width: 0%;
        height: 0rem;

    }

    .aboutmidcontent span {
        color: skyblue;
        font-size: .8rem;
        margin-top: 2rem;

    }
.vision img{
    height: 15rem;
    width: 20rem;
}
    .aboutmidcontent p {
        color: white;
        font-size: 2.5rem;
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
    }

    .about1img img {
        margin-left: 2rem;
        height: 15rem;
        width: 20rem;
    }

    .about2img img {
        margin-left: 1rem;
    }

    .aboutfounder {
        width: 35rem;
        height: 35rem;
        display: block;
    }
   
    .profile-card {
        display: block;
        margin-left: .5rem;
        width: 35rem;
        height: 37rem;
    }
    /* .profile-card {
        display: block;
        margin-left: .5rem;
        width: 23.75rem;
        height: 43.75rem;
    } */
    .profile-card:hover .aboutfounder {
        width: 35rem;
        height: 35rem;
    }

    .profile-card:hover {
        width: 35rem;
        height: 37rem;
    }

    .profile-card:nth-child(2) {
        margin-left: .5rem;
    }

    .profile-card:nth-child(2) h3 {
        text-align: start;
        margin-left: 0rem;  

    }

    .profile-card:nth-child(2):hover h3 {
        text-align: start;
        margin-left: 0rem;

    }

    .profile-card:nth-child(2) p {
        width: 100%;
        text-align: justify;
    }
    .profile-card:nth-child(2) .caption p {
        margin-left: 0rem;
    }
  
    .profile-card:nth-child(2) .caption h3 {
        margin-left: 0rem;
    }
  
    .profile-card:nth-child(2)  img {
        margin-left: 0rem;
    }

    .profile-card:nth-child(2) .foundersocial {
        margin-left: 0rem;
        width: 10rem;
        margin-right: 0rem;

    }
.foundermembers{
    margin-left: 10%;
}
  
 
  
    .profile-card:hover .founderimg {
        transform: translateY(-80px);
    }
    




}
@media (max-width: 774px) {
.team-row{
    column-gap: 2rem;
}
}
@media (min-width: 375px) and (max-width: 670px) {
    .about1in{
        margin-left: -.5rem;
    }
    .about1in p {
        font-size: 1.4rem;
        margin-bottom: 2rem;   
    width: 89%;
    }
    .founder{
        padding-bottom: 0rem;
        margin-bottom: 0rem;
    }
    .aboutmidcontent span {
        font-size: .7rem;

    }
    .aboutmidcontent p {
        font-size: 2rem;

    }
    .founder{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
 
    .visionimg img{
        height: 10rem;
        width: 18rem;
    }
    .about1img img{
        height: 10rem;
        width: 18rem;
    }
    .aboutfounder {
        width: 20rem;
        height: 43.75rem;
        display: block;
    }
  
   .foundermembers{
    margin-left: -8%;
   }
    .profile-card {
        display: block;
        margin-left: 1.5rem;
        width: 20rem;
        height: 52rem;
    }
    .profile-card:hover .aboutfounder {
        width: 20rem;
        height: 43.75rem;
    }

    .profile-card:hover {
        width: 20rem;
        height: 52rem;
    }

    .profile-card:nth-child(2) {
        margin-left: 1.5rem;
    }

    .profile-card:nth-child(2) h3 {
        text-align: start;
        margin-left: 0rem;

    }

    .profile-card:nth-child(2):hover h3 {
        text-align: start;
        margin-left: 0rem;

    }

    .profile-card:nth-child(2) p {
        width: 100%;
        text-align: justify;
    }
    .profile-card:nth-child(2) .caption p {
        margin-left: 0rem;
    }
  
    .profile-card:nth-child(2) .caption h3 {
        margin-left: 0rem;
    }
  
    .profile-card:nth-child(2)  img {
        margin-left: 0rem;
    }

    .profile-card:nth-child(2) .foundersocial {
        margin-left: 0rem;
        width: 10rem;
        margin-right: 0rem;

    }

 
    .title-box {
        text-align: center;
        margin: 30px auto 50px;
    
    }
    .team-row{
        grid-template-columns: 1fr ;
    }
    
      .title-box h1{
        font-size: 2.2rem;
     }
     .title-box p{
        font-size: 2.2rem;
     }
}
@media (max-width : 560px) {
.team-row{
    grid-template-columns: 1fr ;
}
}

@media (max-width : 590px) {
    #aboutmid{
        height: 60vh;
    }
}

@media (max-width : 425px) {
    #aboutmid{
        height: 35vh;
    }
    .aboutjmja{
        font-size: 1.2rem;
    }
    .about1in p {
        font-size: 1.2rem;
        margin-bottom: 2rem;   
    width: 89%;
    }
}
@media (max-width : 375px) {

    .visionimg img{
        height: 10rem;
        width: 18rem;
    }
    .about1img img{
        height: 10rem;
        width: 18rem;
    }
    .aboutfounder {
        width: 20rem;
        height: 46rem;
        display: block;
    }
    .profile-card {
        width: 20rem;
        height: 55rem;
        display: block;
    }


    .profile-card:nth-child(2) h3 {
        text-align: start;
        margin-left: 0rem;

    }

    .profile-card:nth-child(2):hover h3 {
        text-align: start;
        margin-left: 0rem;

    }

    .profile-card:nth-child(2) p {
        width: 100%;
        text-align: justify;
    }
    .profile-card:nth-child(2) .caption p {
        margin-left: 0rem;
    }
  
    .profile-card:nth-child(2) .caption h3 {
        margin-left: 0rem;
    }
  
    .profile-card:nth-child(2)  img {
        margin-left: 0rem;
    }

    .profile-card:nth-child(2) .foundersocial {
        margin-left: 0rem;
        width: 10rem;
        margin-right: 0rem;
    }

.profile-card:hover{
    width: 20rem;
    height: 53rem;
}
.profile-card:hover .aboutfounder{
    width: 20rem;
        height: 46rem;
}
.team-row{
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
}


.title-box h1{
font-size: 1.8rem;
}
.title-box p{
font-size: 1.6rem;
}

}
@media (max-width : 425px){
    #team{
        height: 35vh;
    }
}

@media (max-width : 374px){
    .founder{
        margin-bottom: 0rem;
        padding-bottom: 0rem;
    }
    .aboutjmja{
        font-size: 1rem;
        padding: .5rem;
        padding-top: 0rem;
        margin-bottom: 2rem;
        }
        .vision{
            padding: .5rem;
        }
        .about1in{
            margin-left: -2rem;
        }
        .about1in p {
            width: 100%;
            font-size: 1rem;
            margin-bottom: 2rem;
        }
        .visionimg{

            margin-left: 0rem;
        }
    .visionimg img{
        height: 10rem;
        width: 15rem;
    }
    .about1img img{
        height: 10rem;
        width: 15rem;
    }
    .profile-card {
        width: 70%;
        height: 56rem;
        display: block;
    }
    .profile-card:hover {
        width: 70%;
        height: 57rem;
    }
    .profile-card:hover .aboutfounder {
        width: 17rem;
    }

    .profile-box-desc{
      font-size: 1rem;
    }
    .aboutfounder {
        width: 17rem;
        display: block;
    }
 
    .foundermembers{
        margin-left: -4.5rem;
    }
.caption{
width: 10rem;

}

}

@media (max-width : 374px){



}
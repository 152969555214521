.notfound{
    margin-top: 2rem;
    margin-bottom: 3rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
background-image: linear-gradient(to bottom, #103c64, #204f7c, #2f6395, #3d78af, #4b8dc9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.notfoundnum{
    color: white;
    text-align: center;
    font-size: 12vw;
}
.notfoundcontent{
    color: white;
    text-align: center;
}
.notfoundcontent h2{
    color: white;
    text-align: center;
    font-size: 3vw;
    margin-bottom: 2rem;
}
.notfoundcontent p{
    font-size: 1.5vw;
    color: white;
    text-align: center;
}


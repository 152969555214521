.gallery-image-mobile{
  display: none;
}
.gallery-header h1{
  font-size: 3.5rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600; 
  color: #053B62;
  margin-top: 3rem;
}
  .gallery-image{
    display: flex;
    justify-content: center;
    margin-top: -35rem;
    margin-left: 5rem;
   
  }

.gallery-content p{
font-size: 1.4rem;
text-align: center;
margin: 10rem;
margin-top: 3rem;

}

.customer-logos {
  width: 35rem;
  height: 100rem; 
}

.slide img {
  width: 250px;
  height: 300px;
  object-fit: cover;


}
.gallery-container1{
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 40rem;
  height: 77rem;
  overflow: hidden;
}
.gallery-container2{
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 40rem;
  height: 77rem;
  overflow: hidden;
  margin-left: -6.2rem;
  
}


#first{
  transform: translateX(25%);
  transform: translateY(75%);
}
#second{
  transform: translateX(103%);
}
#third{
  margin-left: 17rem;
  transform: translateY(75%);
}
#forth{
  transform: translateX(0%);
}

.gallery-container {
  display: flex;  /* Arrange sliders side by side */
}

.slider-container {
  width: 41%;  /* Adjust width based on your design */
  height: 80rem;
  overflow: hidden; /* Prevent overflow */

}


@media (max-width: 1275px){
  .gallery-content p{
    font-size: 1.4rem;
    text-align: center;
    margin: 5rem;
    margin-bottom: 8rem;
    }
    .customer-logos {
      width: 35rem;
      height: 100rem; 
    }
    .slide img {
      width: 200px;
      height: 250px;
      object-fit: cover;

    }
    .gallery-container1{
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      width: 32rem;
      height: 77rem;
      overflow: hidden;
    }
    .gallery-container2{
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      width: 32rem;
      height: 77rem;
      overflow: hidden;
      margin-left: -5rem;
    }
    .slider-container {
      width: 41%;  
    
    }

    
#third{
  margin-left: 13.5rem;
}

}
@media (max-width: 1040px){
  .gallery-image{
    margin-left: 10rem;
  }
  .gallery-content p{
    font-size: 1.4rem;
    text-align: center;
    margin: 2rem;
    margin-bottom: 15rem;
    }
    .customer-logos {
      width: 35rem;
      height: 100rem; 
    }
    .slide img {
      width: 150px;
      height: 200px;
      object-fit: cover;

    }
    .gallery-container1{
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      width: 32rem;
      height: 60rem;
      overflow: hidden;
    }
    .gallery-container2{
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      width: 32rem;
      height: 60rem;
      overflow: hidden;
      margin-left: -11rem;
    }
    .slider-container {
      width: 32%;  
    
    }

    
#third{
  margin-left: 10.5rem;
}

}

@media (max-width: 1040px){
  .gallery-header h1{
    font-size: 2.5rem;
      }
  .gallery-container2{
   display: none;
  }
  .gallery-content p{
    font-size: 1.3rem;
    text-align: center;
    margin: 2rem;
    margin-bottom: 10rem;
    }
  #first{
    transform: translateX(25%);
    transform: translateY(100%);
  }
  #second{
    transform: translateX(103%);
    margin-top: -1rem;
  }



}
@media (max-width: 773px){


  .gallery-container1{
  height: 52rem;
   }

}
@media (max-width: 680px){
  .gallery-content p{
    margin: 1rem;
    margin-top: 3rem;
    
    }
  .gallery-header h1{
font-size: 2rem;
  }
  .gallery-image{
    display: none;
  }
  .gallery-image-mobile{
    display: block;
    margin-top: 5rem;
  }
.mobile-slider .slide{
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(23%);
}
  .gallery-image-mobile .slide img{
   width: 50%;
   height: auto;
  }

}
@media (max-width: 425px){
  
.gallery-content p{
  margin: 1rem;
  margin-top: 3rem;
  
  }
  .gallery-content p{
font-size: 1.2rem;
  }
  .gallery-image-mobile .slide img{
    width: 70%;
    height: auto;
   }
   .mobile-slider .slide{
    transform: translateX(15%);
  }
}
* {
  margin: 0;
  font-family: "Zilla Slab", Sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Shippori+Antique&display=swap");

body {
  overflow-x: hidden;
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  position: relative;
  background: #fff;
  padding: 3rem;
  border-radius: 8px;
  width: 80%; /* Adjust width for responsiveness */
  max-width: 700px;
  z-index: 1001; /* Ensure content is above the pseudo-element */
  color: white;
  background-color: rgba(0, 0, 0, 0.514);
  overflow: hidden; /* Ensure content doesn't overflow */
}

/* Modal Background Image */
.modal-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./homeimg/celebrateimg.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.6;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  
}
.modal-title {
text-align: center;
font-size: 1.7rem;
  
}

.close-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.5rem;
  border: 1px solid white;
}

/* Modal Body */
.modal-body {
  margin-top: 1rem;
  font-size: 1.1rem; /* Adjust font size for better readability */
  text-align: center;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

/* Button Styles */
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

@media (max-width: 1300px) {
  .modal-content{
    top: 20%;
  }
}
/* Responsive Adjustments */
@media (max-width: 768px) {
  .modal-title {
    text-align: center;
    font-size: 1rem;
      margin-left: 5rem;
    }
  .modal-content {
    padding: 1rem;
    width: 95%;
  }

  .close-button {
    font-size: 1.5rem;
    padding: 0.25rem;
  }

  .modal-body {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {

  .modal-title {
    text-align: center;
    font-size: 1.2rem;
    margin-left: 2rem;
    }
  .modal-content {
    width: 100%;
    padding: 0.5rem;
    top: 10%;
  }

  .close-button {
    font-size: 1.25rem;
  }

  .modal-body {
    font-size: 0.8rem;
  }
}

.mid {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  background-image: url('./homeimg/home.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 80vh;
}


.midcontent {
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.497);
  padding: 1rem;
  /* border: 1px solid rgb(255, 255, 255); */
  width: 100%;
  margin-top: auto;
}

.midcontent p:nth-child(1) {
  color: white;
  font-size: 2vw;
  padding-bottom: 1rem;
  border-bottom: 3px solid #7AB65C;

}

.midcontent p:nth-child(2) {
  color: white;
  font-size: 3.5vw;

}


/* -----------intro------------- */
.intro {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.introtop {
  font-size: 3.5rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
  color: #053B62;
}

.introbox {
  display: flex;
  margin: 1rem auto;
  position: relative;
  justify-content: center;
  max-width: 100%;
  /* Ensure it doesn’t overflow the viewport */

}


.introboxcontent {
  margin-left: 1rem;
  box-shadow: 0 8px 12px -6px black;
  width: 40%;
  padding: 1rem;
  padding-top: 3rem;
  background-color: #053B62;
  margin-left: 5rem;
}

.introbox img {
  width: 35%;
}

.introbox p {
  font-size: 1.3rem;
  color: rgb(255, 255, 255);
  padding-bottom: 1rem;
  text-align: justify;
}

.introbox button {
  padding: 6px;
  font-size: 1.2rem;
  margin-top: .5rem;
  cursor: pointer;
}

.introbox button a {
  text-decoration: none;
  color: black;
}

.introbox button:hover a {
  transition: .2s ease;
}

.introbox button:hover {
  color: black;
  transition: .3s ease;
}

.aboutbtn {
  margin-left: 0rem;
}

.introcontent {
  display: flex;
  align-items: center;
}



/* ----------------WOrk------------- */
.underone {
  margin-bottom: 4%;
}

.work {
  background-image: linear-gradient(to bottom, #ffffff, #c8c8d6, #9196ae, #576688, #103c64);
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8%;
}

.workupper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.workupper h1 {
  font-size: 3.5rem;
  margin-bottom: 3%;
  color: #103c64;
}

.workupper span {
  font-size: 3.5rem;
  color: #103c64;
  font-weight: 700;
}

.workupper .underone {
  font-size: 4rem;
  color: #103c64;
  font-weight: 700;

}

.workcontent1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  margin-left: 20%;
  margin-right: 15%;

}

.workitem1 {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 2%;
  background-color: white;
  box-shadow: 0 8px 12px -6px black;
  margin: 3%;
  margin-top: 3%;
  padding-bottom: 2rem;
}


.workitem1:hover {
  box-shadow: 0 8px 30px 0px #103c64;

  transform: scale(1.2);
  transition: .3s ease-in-out;
  border-bottom: 5px solid #103c64;
  border-top: 5px solid #103c64;
  border-radius: 40px;
}

.workitem1 img {
  height: 8rem;
  margin-bottom: 1rem;


}

.workitem1 {
  margin-top: 1rem;
  width: 80%;
}


.workitem1 div {
  width: 100%;
}



.workitem1 p {
  font-size: 1vw;
  color: grey;
  margin-top: 0rem;
  text-align: center;

}

.workitem1 .itempupper {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.5vw;
  font-weight: 600;

}

.workitem1 button {
  font-size: .9rem;
  padding: 3%;
  background-color: transparent;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 10%;
}

.workitem1 button a {
  color: black;
  text-decoration: none;
}



.workitem1 button:hover {
  border: 1px solid gray;
  background: white;
  color: #103c64;
  transition: .3s ease;


}

.showmorework {
  margin-bottom: 3%;
  margin-top: 3%;
  display: block;
  background-color: transparent;
  cursor: pointer;
  /* padding: 1%; */
  transition: .3s ease;
  /* border: 1px solid white; */
}

.showmorework a {
  text-decoration: none;
  color: white;
}

.showmorework i {
  text-decoration: none;
  color: black;
  background-color: transparent;
  cursor: pointer;
  font-size: 2.5rem;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid black;
  transition: .3s ease;
}

.showmorework i:hover {
  background-color: rgb(231, 231, 231);
  color: black;
  border: 1px solid black;
}

/* ----------------counter---------------------- */
.wrapper {
  background-image: url(./homeimg/counterbg.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-around;
  gap: 1.2%;
  margin-bottom: 8%;
  width: 100%;
  /* height: 25rem; */
  /* margin-top: 0rem; */

}

.wrappercontent {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 20%;
  height: 28vmin;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  position: relative;
  font-size: 1.6vw;
  border-radius: 0.5em;
  background-color: #103c64;
  border-bottom: 10px solid #7ab65c;
  z-index: 2;
}

.wrapper i {
  color: #7ab65c;
  font-size: 4vw;
  text-align: center;
}

span.num {
  color: #ffffff;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 4vw;
}

div.text {
  color: #e0e0e0;
  font-size: 1em;
  text-align: center;
  pad: 0.7em 0;
  font-weight: 400;
}

@media(width >=1500px) {
  .workitem1 .itempupper {
    font-size: 1vw;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }




}


@media only screen and (max-width: 1440px) {

  .introboxcontent {
    padding-top: 1rem;
    width: 50%;
    margin-left: 2rem;
  }


  .workmore {
    display: none;
  }


  .workupper .underone {
    font-size: 3.5rem;
    color: #103c64;
    font-weight: 700;

  }


  .introbox p {
    font-size: 1.1rem;
    margin-right: 2rem;
    padding-bottom: .5rem;
  }

  .introbox button {
    font-size: 1rem;
  }

}

@media only screen and (max-width: 1140px) {
  .introboxcontent {
    padding-top: 1rem;
    width: 55%;
    margin-left: 2rem;
    font-size: 1.5rem;
  }

  .introboxcontent p {
    font-size: 1rem;
  }

}

@media only screen and (max-width: 1050px) {


  .introbox span {
    margin-top: 2rem;
    font-size: 2rem;
    margin-left: 3rem;
    color: white;
  }

  .ps {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .pscontent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }

  .pshead h2 {
    font-size: 3.5rem;
    text-align: center;
    color: #103c64;
  }

  .pshead p {
    margin-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
  }

  .psbox {
    width: 15%;
    height: 15rem;
    margin: 1rem;
    padding: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #628fb9;
    border-radius: 40px;
    box-shadow: 0 8px 10px 0px #3585cf;

  }

  .psbox h2 {
    text-align: center;
    font-size: 1.3rem;
  }

  .psbox p {
    margin-top: 1rem;
    text-align: center;
    font-size: 1.1rem;
  }

  .psbox img {
    object-fit: contain;
    width: 15%;
  }
}

@media (max-width: 1024px) {





  .introbox .introimg img {
    height: 100%;
    margin-left: 5%;
    margin-top: 6rem;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    border: .5rem solid rgb(73, 179, 255);
    box-shadow: 0 8px 48px 1px black;


  }

  .introcontent {

    margin-top: 3rem;
  }

  .introbox span {
    font-size: 1.5rem;
  }

  .introbox p {
    font-size: 1rem;
  }

  .introbox button {
    font-size: .8rem;
    padding: 3px;
  }

  .workupper p {
    font-size: 4vw;
    margin-bottom: 1rem;
  }

  .workupper h1 {
    font-size: 3.5rem;
    color: #103c64;
    text-align: center;
  }

  .workupper .underone {
    font-size: 6vw;
    color: #103c64;
    font-weight: 700;
  }

  .workdown1 {
    margin-left: 5%;

  }

  .workcontent1 {
    width: 100%;
    margin-left: auto;
  }

  .workitem1 p {
    padding: .5rem;
    font-size: 1.5vw;
  }

  .workitem1 div p {
    font-size: 1.8vw;
  }

  .checkwork .workcontent1 .workitem1 {
    width: 30%;
    margin: 1rem;
    margin-top: 3rem;
  }

  .wrapper {
    margin-top: -1rem;
  }

  .workmore {
    display: none;
  }


}

@media (max-width: 970px) {
  .introbox img {
    width: 45%;
  }

  .introboxcontent {
    width: 45%;
  }
}

@media (max-width: 882px) {
  .workitem1 p {
    font-size: 2.5vw;
  }

  .workitem1 .itempupper {
    font-size: 4vw;
  }

  .introbox {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-bottom: 2%;
  }

  .introboxcontent {
    width: 86%;
    padding: 2%;
    margin: 0rem;
  }

  .introbox img {
    width: 90%;
    margin-bottom: 0%;
    overflow: hidden;
  }

  .introbox span {
    margin-top: 1%;
    margin-left: 0%;
  }

  .introbox p {
    margin-top: 1%;
  }

  .introcontent {
    margin-top: 2%;
  }

  #showmorework {
    margin-left: 0rem;
  }
}



@media (min-width: 425px) and (max-width: 768px) {
  .mid {
    height: 60vh;
  }

  .workupper h1 {
    font-size: 2.5rem;
  }

  .introtop {
    font-size: 2.5rem;
  }

  .midcontent {
    width: 100%;
  }

  .midcontent p:nth-child(1) {
    border-bottom: 2px solid #7ab65c;
    margin-bottom: 0rem;
    font-size: 1.3rem;
  }

  .midcontent p:nth-child(2) {
    font-size: 1.8rem;
  }

  .workitem1:hover {
    transform: translate(0);
  }

  .showmorework a {
    font-size: 2.3vw;


  }

  .showmorework {
    padding: .3%;
    font-size: 1.2rem;

  }

  .introbox p button a {
    font-size: 2vw;
  }

  .workcontent1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }


  .wrapper {
    margin-top: -7rem;
  }

  .workitem1 {
    width: 80%;
  }


  .workitem1 img {
    height: 5rem;
  }

  .workitem1 .itempupper {
    font-size: 3.5vw;
  }


  .workitem1 p {
    font-size: .8rem;
    color: grey;
    margin-top: 0rem;
    text-align: center;
  }

  .workitem1 button {
    font-size: .8rem;
    padding: .3rem;
    background-color: transparent;
    border: 1px solid black;
    cursor: pointer;
  }

  .wrapper {
    height: 23rem;
    margin-top: 5rem;
    gap: 0px;

  }

  .wrapper::before {
    height: 23rem;

  }

  .wrappercontent {
    margin-top: 5rem;
    width: 23vmin;
    height: 23vmin;
    flex-direction: column;
    justify-content: space-around;
    padding: 1em 0;
    font-size: 10px;
    border-radius: 0.5em;
  }

  .workmore {
    display: none;
  }

  .showmorework i {
    font-size: 1.7rem;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .workupper span {
    width: 3rem;
  }

}

@media only screen and (max-width: 590px) {
  .close-button{
    margin-left: .5rem;
  }
  .mid {
    height: 50vh;
  }
}

@media (max-width: 485px) {
  .midcontent p:nth-child(2) {
    font-size: 1.5rem;
  }
}


@media (max-width: 425px) {
  .midcontent {
    width: 90%;
  }

  .midcontent p:nth-child(1) {
    border-bottom: 2px solid #7ab65c;
    margin-bottom: 0rem;
    font-size: 1rem;
  }

  .midcontent p:nth-child(2) {
    font-size: 1.3rem;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;

    height: 30rem;
  }

  .wrappercontent {
    margin-top: 0rem;
    width: 80%;
    left: 10%;
  }

  .wrappercontent i {
    font-size: 1.5rem;
  }

  .wrappercontent .text {
    font-size: 1rem;
  }

  .mid {
    height: 35vh;
  }

  .introbox p {
    font-size: .8rem;
  }

  .workcontent1 {
    display: grid;
    grid-template-columns: 1fr;
  }

  .workitem1 img {
    height: 4rem;
  }

  .workitem1 button {
    font-size: 3vw;
    margin-bottom: 1rem;
  }

  .workitem1 p {
    font-size: 5vw;
  }

  .workitem1 .itempupper {
    font-size: 5vw;
  }

  .midcontent p:nth-child(1) {
    margin-bottom: 0rem;
    padding: 0rem;
    border-bottom: 1px solid #7ab65c;
  }

  .showmorework i {
    font-size: 1.4rem;
    padding: .3rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

@media (max-width: 425px) {
  .introtop {
    font-size: 2rem;
  }

  .workupper h1 {
    font-size: 2rem;
  }
}




@media (max-width: 376px) {
  .workupper h1 {
    font-size: 2rem;
  }

  .introtop {
    font-size: 2rem;
  }

  .midcontent {
    width: 100%;
  }

  .midcontent p:nth-child(1) {
    border-bottom: 2px solid #7ab65c;
    margin-bottom: 0rem;
    font-size: .8rem;
  }

  .midcontent p:nth-child(2) {
    font-size: 1rem;
  }

  .showmorework i {
    font-size: 1.2rem;
    padding: .3rem;
    padding-left: .5rem;
    padding-right: .5rem;
  }

}
* {
  margin: 0;
  font-family: "Zilla Slab", Sans-serif;

}
/* @import url("https://fonts.googleapis.com/css2?family=Shippori+Antique&display=swap"); */

#whatsappicon2{
  display: none;
}
.disclamer{
  width: 100%;
  overflow: hidden;
}

.disclamer p:nth-child(1){
  font-weight: 500;
  font-size: 1.5vw;
  width: 100%;
text-align: center;
background-color: black;
position: relative;
color: #fff;
z-index: 1;
overflow: hidden;
box-shadow: 0 8px 12px -6px black;
border-bottom-left-radius: 15px;
border-bottom-right-radius: 15px;
}
.disclamer p:nth-child(1) a{
  color: white;
}

.disclamer p:nth-child(1)::before{
  content: '';
  position: absolute;
  width: 120%;
  height: 100%;
  background: linear-gradient(90deg, transparent 0%, #000 5%, #000 100%);
animation: animate 2s ease-in-out alternate-reverse infinite;
animation-delay: 0s;
}

@keyframes animate {
  0%{
    left: 110%;
  }
  100%{
    left: -20%;
  }
  
}
.disclamer p:nth-child(1) span{
color: #ffffff;
}



.disclamer p:nth-child(2){
margin-top: 1%;
text-align: center;
}


.disclamer p:nth-child(2) span{
font-weight: 600;
}

.whatsappicon{
  border: .7vw solid rgb(4, 181, 4);
  border-radius: 100%;
  position: fixed;
  margin-left: 94%;
  z-index: 999;
  margin-top: 40%;
}
.whatsappicon a i{
font-size: 1.9rem;
background-color: rgb(4, 181, 4);
color: white;
cursor: pointer;
border-radius: 50%;
 outline: none;
}

/* -----------start---------- */
#hamburger {
  color: black;
  position: relative;
}


.navigation {
  color: white;
  width: 100%;
  height: 7rem;
  box-shadow: 0 8px 12px -6px black;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
}

.navitems {
  display: flex;
  margin: 0rem 0rem 0rem 0rem;
  padding-bottom: 2%;


}

.navtabs {
  color: black;
  margin-left: 6%;

}

.navtabs ul {
  list-style: none;
  display: flex;
  margin-left: 4rem;
  margin-top: 3.5%;
}

.navtabs ul li {
  list-style: none;
  display: flex;
  font-size: 1.8vw;
  margin-left: 4rem;
}
.dropdown{
  display: flex;
  flex-direction: column;
}
.dropdown .dropdown-item{
  display: block;
}
.navtabs ul li a {
  text-decoration: none;
  color: black;

}

.navtabs ul li a::after {
  content: '';
  position: absolute;
  font-size: 1.1vw;

  
  width: 90%;
  height: 3px;
  background-color: #103c64;
  left: 4px;
  bottom: -.5rem;
  transform: scaleX(0);
  border-radius: 20px;
}

.navtabs ul li a:hover::after {
  transform: scaleX(1);
  transition: transform ease .5s;
}

.navtabs ul li:hover {
  transform: scale(1.2);
  transition: transform .2s ease;
}
.navlink.active a {
  color: #7ab65c;
}
#logo img {
  height: 3.5vw;
  margin-top: 13%;
  margin-left: 20%;
}



#hamburger {
  display: none;
}

#hamburger div {
  margin: .2rem;
  width: 1.5rem;
  height: 5px;
  background-color: black;
  border-radius: 10%;
  display: block;
}

#hamburgerclose {
  margin-top: 2rem;
  display: none;
}

#hamburgerclose div {
  margin: .2rem;
  width: 1.7rem;
  height: 5px;
  background-color: black;
  border-radius: 10%;
}

/*--------------- mid--------------- */
@media (width >= 1500px) {

  #navoption li {
    font-size: 1.7rem;
  }


}

@media only screen and (max-width: 1640px) {
  #navoption{
    margin-left: 1rem;
  }
  #navoption li{
    margin-left: 3rem;
  }
}

@media only screen and (max-width: 1440px) {
  .workmore{
    display: none;
  }
  
  #navoption li {
    font-size: 1.3rem;
  }


}

@media only screen and (max-width: 1240px) {
  .navtabs ul {
    margin-left: 2rem;
    margin-top: 4%;
  }
  
  .navtabs ul li {
    margin-left: 1rem;
  }
}


/* Medium only screens (tablets) */
@media only screen and (max-width: 1024px) {
  .whatsappicon{
    margin-left: 92%;
    margin-top: 45rem;
  }
  
  .navtabs ul li a::after {
    left: -3px;
  }
  
  .navtabs ul li {
    list-style: none;
    display: flex;
    margin-left: 1.7rem;
  }

  .navtabs ul {
    margin-top: 2.3rem;
  }

  .navtabs ul li a {
    font-size: 1rem;
    margin-left: -.8rem;
    padding: .2rem;
  }


  #logo img {
    margin-top: 30%;
    margin-left: 25%;
  }


}
@media only screen and (max-width: 940px) {

  #navoption li {
    margin-left: 2rem;
  }


}
@media only screen and (max-width: 825px) {

  #navoption li {
    margin-left: 1.4rem;
  }


}




@media only screen and (max-width: 768px) {
  #whatsappicon1{
    display: none;
  }
  #whatsappicon2{
    display: block;
  }
  #navoption,
  #navigation {
    transition: height 0.3s ease, display 0.3s ease;
  }

  #navigation {
    height: 8.5rem;
  }

  #navoption {
    overflow: hidden;
    display: none;
    position: absolute;
    top: 1rem;
    gap: 0;
    margin-top: 7vw;
    margin-left: 2rem;
  }

  #navoption li {
    margin-top: .2rem;
  }

  .navtabs ul li a::after {
    content: '';
    position: absolute;
    font-size: 0vw;
    width: 0%;
    height: 0px;
    background-color: transparent;
    left: 4px;
    bottom: -.5rem;
    transform: scaleX(0);
  }
  
  .navtabs ul li a:hover::after {
    transform: scaleX(0);
  }
  
  #navoption li a {
    font-size: 2.5vw;
    font-weight: 500;
  }

  #navoption.active {
    left: 0;

  }

  #hamburger {
    margin-left: 20rem;
    /* position: absolute; */
    display: block;
    margin-top: 1.5rem;
  }

  #hamburgerclose {
    margin-left: 20rem;
    /* position: absolute; */
  }

  #hamburgerclose div:nth-child(1) {
    transform: translateY(4px) rotate(-45deg);
    transition: transform .3s ease;

  }

  #hamburgerclose div:nth-child(2) {
    transform: translateY(-4px) rotate(45deg);
    transition: transform .3s ease;

  }
  #logo img{
    margin-top: 20%;
  }
  
  
}

@media (min-width: 425px) and (max-width: 768px) {
  .disclamer p:nth-child(1){
    font-size: 4vw;
  }
  .navtabs ul li a::after {
    content: '';
    position: absolute;
    font-size: 0vw;
    width: 0%;
    height: 3px;
    background-color: #103c64;
    left: 4px;
    bottom: -.5rem;
    transform: scaleX(0);
  }
  
  .navtabs ul li a:hover::after {
    transform: scaleX(0);
    transition: transform ease .5s;
  }
  
  #navoption ul li a{
    font-size: 3vw;
  }
  #navoption{
    margin-top: 9rem;
  }
  #logo img{
    margin-top: 30%;
    height: 7vw;
  }
  #hamburger{
    margin-left: 52vw;
    margin-top: 15%;
  }
  #hamburgerclose{
margin-left: 52vw;
margin-top: 15%;
  }
  .navtabs ul li a::after {
    content: '';
    position: absolute;
    font-size: 0vw;
    width: 0%;
    height: 0px;
    background-color: transparent;
    left: 4px;
    bottom: -.5rem;
    transform: scaleX(0);
  }
  
  .navtabs ul li a:hover::after {
    transform: scaleX(0);
  }
}
@media (min-width: 375px) and (max-width: 426px) {
  .whatsappicon{
    border: 0vw solid rgb(4, 181, 4);
  }
  .disclamer p:nth-child(1){
    font-size: 4vw;
  }
  #navoption ul li a{
    font-size: 3vw;
  }

  #navoption{
    margin-top: 8rem;
  }

  #logo img{
    height: 3rem;
    margin-top: 2.8rem;
  }
  #hamburger{
    margin-left: 30vw;
    margin-top: 12vw;
  }
  #hamburgerclose{
margin-left:  30vw;
margin-top: 12vw;
  }

  .whatsappicon{
    margin-left: 88%;
  }
  .navtabs ul li a::after {
    content: '';
    position: absolute;
    font-size: 0vw;
    width: 0%;
    height: 0px;
    background-color: transparent;
    left: 4px;
    bottom: -.5rem;
    transform: scaleX(0);
  }
  
  .navtabs ul li a:hover::after {
    transform: scaleX(0);
  }
  
}

@media only screen and (max-width: 500px) {
  #navoption  li a{
    font-size: 1rem;
  }


}

@media (max-width : 375px){
  .disclamer p:nth-child(1){
    font-size: 4vw;
  }
  #logo img{
    height: 2rem;
    margin-top: 4rem;
  }
  #hamburger{
    margin-left: 10rem;
    margin-top: 4rem;
    
  }
  #hamburgerclose{
    margin-left: 10rem;
    margin-top: 4rem;

  }

  #navoption{
    margin-top: 10rem;
  }
}
@media (max-width: 370px){

}
@media(min-width: 280px) and (max-width: 320px){

#navoption{
  margin-top: 10rem;
}
#navoption li a{
font-size: 1rem;
}
#logo img{
  margin-top: 3rem;
}
  #hamburger{
    margin-left: 40vw;
    margin-top: 16vw;
  }
  #hamburgerclose{
margin-left:  40vw;
margin-top: 16vw;
  }

  .whatsappicon{
    margin-left: 83%;
  }
}


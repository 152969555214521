.contact {
    width: 100%;
    background-image: linear-gradient(to bottom, #ffffff, #c8c8d6, #9196ae, #576688, #103c64);
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
  }
  
  .contacthead h1{
    text-align: center;
    margin-bottom: 7rem;
    font-size: 3.5rem;
    color: #103c64;
    font-weight: 700;
    
  }
  
  .contactcontent {
    width: 30rem;
    height: 38rem;
    background-color: #103C64;
    margin-left: 0rem;
  }
  
  .contactmenu {
    display: flex;
  justify-content: space-around;
  }
  
  .contactcontainer {
    margin-left: 5rem;
    margin-right: 5rem;
    padding-top: 5rem;
    color: white;
  }
  
  .contactdetails {
    margin-bottom: 3rem;
  }
  
  .contactdetails h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .contactdetails p {
    font-size: 1.2rem;
  }
  
  .contactdetails p a {
    color: skyblue;
  }
  
  .contactadress {
    margin-bottom: 3rem;
  }
  
  .contactadress h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  
  }
  
  .contactadress p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  
  }
  
  .contactsocial h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .contactsocial a {
    color: white;
    padding-right: 2rem;
  
  }
  
  .contactsocial a i {
    font-size: 1.5rem;
  }
  
  .contactform {
    margin-right: 4rem;
    margin-left: -2rem;
  }
  
  .contactformhead h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .contactformhead p {
    font-size: 1.2rem;
    margin-bottom: 3rem;
  
  }
  
  
  
  /*//////////////*/
  
  
  .formdata {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  
  .contact form label {
    display: block;
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .lastname,
  .email {
    margin-left: 1rem;
  }
  
  .contact form textarea {
    resize: none;
  }
  
  .contactformbtn button {
    padding: .5rem;
    background: #103C64;
    border: 0;
    color: #e4e4e4;
    text-transform: uppercase;
    font-size: 1.5rem;
    cursor: pointer;
    font-weight: bold;
  }
  
  .contactformbtn button:hover,
  .contactformbtn button:focus {
    background: #1761a5;
    color: #ffffff;
    outline: 0;
    transition: background-color 2s ease-out;
  }
  
  .contactformbtn {
    margin-top: 2rem;
  }
  
  .contact form button{
    width: 104%;
    padding: 1em;
    border: solid 1px #627EDC;
    border-radius: 4px;
  }
  .contact form input,
  .contact form textarea {
    width: 100%;
    padding: 1em;
    border: solid 1px #627EDC;
    border-radius: 4px;
  }
  .contact form textarea {
    width: 205%;
  }
  .map-container iframe{
  width: 99%;
  height: 600px;
  }
  .map-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .maphead h1{
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #103C64;
    font-weight: 600;
  }
  .map-container{
    margin-bottom: 8rem;
  }

@media (width >= 1800px){
  .contactmenu{
    display: flex;
    /* justify-content: space-around; */
    /* justify-content: ; */

  }
  .contactcontent{
    width: 40rem;
  }
}


  @media only screen and (max-width: 1500px) {
.contactform{
    margin-left: 2rem;
}
.contactmenu{
  justify-content: center;
}
.contactcontent{
  margin-left: 1rem;
}
.contacthead div {
  border-radius: 30%;
  width: 10rem;
  height: 5px;
  background-color: black;
  text-align: center;
  margin-left: 42.5%;
}
.contactform {
  margin-left: 3rem;
}

.contact form button,
.contact form input
{
  width: 80%;
  padding: 1em;
  border: solid 1px #627EDC;
  border-radius: 4px;
}

.contactcontainer {
  margin-left: 3rem;
  margin-right: 3rem;
  padding-top: 4rem;

}

.contact form button {
  width: 95%;
}
.contact form textarea {
  width: 190%;
}

.contact form button {
  width: 97%;
}


.contactcontent {
  height: 50rem;
}
  }


  @media only screen and (max-width: 1024px) {
    .map-container iframe{
      height: 600px;
      }
    .contactcontent {
      margin-left: 3rem;
    }
    .contacthead div {
      border-radius: 30%;
      width: 10rem;
      height: 5px;
      background-color: black;
      text-align: center;
      margin-left: 42.5%;
    }
    .contactform {
      margin-left: 3rem;
    }
  
    .contact form button,
    .contact form input
    {
      width: 80%;
      padding: 1em;
      border: solid 1px #627EDC;
      border-radius: 4px;
    }
  
    .contactcontainer {
      margin-left: 3rem;
      margin-right: 3rem;
      padding-top: 4rem;
  
    }
  
    .contact form button {
      width: 95%;
    }
    .contact form textarea {
      width: 190%;
    }
    
    .contact form button {
      width: 97%;
    }
    
  
    .contactcontent {
      height: 50rem;
    }
  }
  
  
  @media (min-width: 425px) and (max-width: 768px) {

    .contacthead h1{
      margin-bottom: 4rem;
      font-size: 2.8rem;
      
    }
    .maphead h1{
      font-size: 2.8rem;

    }
    .map-container iframe{
        height: 400px;
        }
    
      .contactcontent {
        margin-left: 1.5rem;
      }
      .contacthead div {
        border-radius: 30%;
        width: 10rem;
        height: 5px;
        background-color: black;
        text-align: center;
        margin-left: 40%;
      }
      .contactcontainer {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-top: 2rem;
        color: white;
      }
    
      .contactsocial h3 {
        font-size: 1.2rem;
      }
    
      .contactsocial a {
        color: white;
        padding-right: 1.5rem;
    
      }
    
      .contactsocial a i {
        font-size: 1.5rem;
      }
      .contactcontent {
        height: 45rem;
      }
    .contacthead div {
      border-radius: 30%;
      width: 10rem;
      height: 5px;
      background-color: black;
      text-align: center;
      margin-left: 40%;
    }
    .contactsocial a {
      color: white;
      padding-right: 1.2rem;
  
    }
  
    .contactadress {
      margin-bottom: 1rem;
    }
  
    .contact form button,
    .contact form input {
      width: 70%;
      padding: 1em;
      border: solid 1px #627EDC;
      border-radius: 4px;
    }
  
    .contact form textarea {
      width: 185%;
    }
  
    .contactcontent {
      height: 48rem;
    }
    .contactcontent {
      height: 42rem;
    }
      
    .contact form button {
      width: 95%;
    }
    .contactcontent {
        width: 35rem;
        margin-left: .5rem;
      }
      
    .contactcontainer {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 3rem;
      }
      
    .contactdetails h3 {
        font-size: 1.1rem;
        margin-bottom: .2rem;
      }
      
      .contactdetails p {
        font-size: 1rem;
      }
      
      .contactdetails p a {
        color: skyblue;
      }
      
      .contactadress {
        margin-bottom: .2rem;
      }
      
      .contactadress h3 {
        font-size: 1.2rem;
        margin-bottom: .5rem;
      
      }
      
      .contactadress p {
        font-size: 1rem;
        margin-bottom: .5rem;
      
      }
      .contactsocial{
        margin-top: 2rem;
      }
      .contactsocial h3 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
      
      .contactsocial a {
        color: white;
        padding-right: 2rem;
      
      }
      
      .contactsocial a i {
        font-size: 1.2rem;
        padding-top: 1rem;
      }
      
  
         
  .contactform {
    margin-right: 1rem;
    margin-left: 2rem;
  }
      .contactformhead h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      
      .contactformhead p {
        font-size: 0.8rem;
        margin-bottom: 3rem;
      
      }
      .formdata {
        display: grid;
        grid-template-columns: 1fr ;
        grid-gap: 15px;
      }
        
  .contact form label {
    display: block;
    font-size: 1rem;
    color: rgb(0, 0, 0);
    font-weight: 500;
    margin-bottom: 0.3rem;
  }
  
      .lastname,
      .email {
        margin-left: 0rem;
      }
      
      .contact form textarea {
        width: 70%;
      }
      .contact form button{
        width: 85%;
        padding: .5em;
        font-size: 1.2rem;
      }
    
  }
  
  @media (min-width: 375px) and (max-width: 425px) {
    .contacthead h1{
      margin-bottom: 2rem;
      font-size: 2.5rem;
      
    }
    .map-container iframe{
    height: 300px;  
    }
    .contactcontent {
        width: 35rem;
        margin-left: .5rem;
      }
      
    .contactcontainer {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 3rem;
      }
      
    .contactdetails h3 {
        font-size: 1.1rem;
        margin-bottom: .2rem;
      }
      
      .contactdetails p {
        font-size: 1rem;
      }
      
      .contactdetails p a {
        color: skyblue;
      }
      
      .contactadress {
        margin-bottom: .2rem;
      }
      
      .contactadress h3 {
        font-size: 1.2rem;
        margin-bottom: .5rem;
      
      }
      
      .contactadress p {
        font-size: 1rem;
        margin-bottom: .5rem;
      
      }
      
      .contactsocial h3 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
      
      .contactsocial a {
        color: white;
        padding-right: 2rem;
      
      }
      
      .contactsocial a i {
        font-size: 1.2rem;
        padding-top: 1rem;
      }
      
  
      .contactform {
        margin-left: 1rem;
      }

      .contactformhead h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      
      .contactformhead p {
        font-size: 0.8rem;
        margin-bottom: 3rem;
      
      }
      .formdata {
        display: grid;
        grid-template-columns: 1fr ;
        grid-gap: 15px;
      }
        
  .contact form label {
    display: block;
    font-size: 1rem;
    color: rgb(0, 0, 0);
    font-weight: 500;
    margin-bottom: 0.3rem;
  }
  
      .lastname,
      .email {
        margin-left: 0rem;
      }
      
      .contact form textarea {
        width: 70%;
      }
      .contact form button{
        width: 85%;
        padding: .5em;
        font-size: 1.2rem;
      }
  }
  @media (max-width: 390px) {
.contactmenu{
  flex-direction: column;
}

.contactcontent{
 width: 100%;
 margin-left: 0rem;
 height: auto;
 padding-bottom: 2rem;
 margin-bottom: 2rem;
}
.contact form textarea {
  width: 80%;
}


  }
  @media (min-width: 320px) and (max-width: 376px) {
    .contacthead h1{
      margin-bottom: 4rem;
      font-size: 2.5rem;
      
    }
    .contactcontent{
     width: 100%;
    }
    .map-container iframe{
        height: 400px;
        } 
    .contactform {
      margin-left: .2rem;
      margin-right: .2rem;
    }
  
    .contact form textarea {
      width: 80%;
    }

  }

  @media(max-width: 320px){
    .contacthead h1{
      margin-bottom: 4rem;
      font-size: 2.5rem;
      
    }
    .maphead h1{
      font-size: 2.5rem;

    }
    .map-container iframe{
      height: 400px;
      } 
  .contacthead div {
    border-radius: 30%;
    width: 10rem;
    height: 5px;
    background-color: black;
    text-align: center;
    margin-left: 39%;
  }
  .contactform {
    margin-left: 1rem;
  }

  .contact form textarea {
    width: 80%;
  }

  .contactcontent{
    margin-left: 0rem;
    width: 100%;
  }
  .contactmenu{
    flex-direction: column;
    
  }

  .formdata{
    grid-template-columns: 1fr ;
  }
  .lastname, .email{
    margin-left: 0rem;
  }
  .contactform{
    margin-top: 3rem;
  }
  .contactformhead h2{
    font-size: 2rem;
  }
  .contactformhead p{
    font-size: 1rem;
    margin-right: 1rem;
  }
  }
.disclamerpage{
    margin-top: 4rem;
    background-image: linear-gradient(to top, #93cbff, #bad6ff, #d7e2ff, #eeefff, #ffffff);
    padding-bottom: 2rem;
}

.disclamerpagehead{
text-align: center;
margin-bottom: 2rem;
}
.disclamerpagehead h1{
text-align: center;
margin-bottom: 2rem;
font-size: 3.5rem;
color: #053D62;
}
.disclamerpagecontent{
    font-size: 1.2vw;
    margin: 3rem;
    line-height: 1.7;
    text-align: justify;
}

@media (max-width: 1025px){

.disclamerpagehead{
    font-size: 2.7vw;
}


.disclamerpagecontent{
    font-size: 1.5vw;
}

}
@media (max-width: 768px){
    .disclamerpagehead h1{
        font-size: 2.5rem;
        }
.disclamerpagehead{
    font-size: 3.3vw;
}


.disclamerpagecontent{
    font-size: 2vw;
}

}
@media (max-width: 425px){
    .disclamerpagehead h1{
        font-size: 2rem;
        }
.disclamerpagehead{
    font-size: 4.5vw;
}


.disclamerpagecontent{
    font-size: 2.7vw;
    margin: 1rem;
}

}
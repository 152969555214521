.footer {
  margin-top: 7rem;
  padding-bottom: 2rem;
  background-color: #103C64;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.footercontent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 3rem;
  margin-top: .5rem;
  color: white;
}

.footerimg img {
  height: 3rem;
  margin-left: 3rem;
  margin-top: -3rem;
  background-color: white;
  border-radius: 10px;
  border: 5px solid #7AB65C;
  padding: 1rem;
}

.footer-about h2,
.footer-links h2,
.adress h2 {
  margin-bottom: 1rem;
}
.footer-about{

  margin-right: 1rem;
}

.footer-about p {
  width: 70%;
  line-height: 1.5;
  text-align: justify;
}

.social {
  margin-top: 2rem;
}

.footer-links {
  margin-left: 8vw;
}

.footer-links li {
  padding: .4rem;
  transition: .3s ease;
  list-style: none;
}

.footer-links li:hover {
  transform: scale(1.1);
  margin-left: 1rem;
}

.footer-links li a {
  padding: .3rem;
  text-decoration: none;
  color: white;
}

.socialicons {
  margin-top: 1rem;
}

.socialicons a i {
  padding-right: .5rem;
  font-size: 1.3rem;
  cursor: pointer;
  transition: .3s ease;
}
.socialicons a {
  color: white;
}

.footer h2 {
  color: #7AB65C;
  font-weight: 600;
}

.socialicons i:hover {
  transform: scale(1.2);
}

.privacyandterms a {
  color: white;
}

.footerbottom {
  background-color: #7AB65C;
  color: white;
  font-size: 1.3rem;
  text-align: center;
  border-top: 2px solid #c4ffa7;
  font-weight: bold;
}
.adress{
  margin-right: 1rem;
}
.adress p {
  line-height: 1.5;
  margin-right: 1rem;
  text-align: justify;
}

.mobile a {
  color: white;
  text-decoration: none;
}
.mobile a:hover{
 color: aquamarine;
}

/* Responsive Styles */
@media (max-width: 768px) {
  #footaddbreak{
    display: none;
  }
  .footercontent {
    grid-template-columns: 1fr;
    margin-left: 1rem;
  }
.social{
  margin-top: 1rem;
}
  .footer-links {
    margin-left: 0rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .footerimg img {
    margin-left: 1rem;
  }

  .footer-about p {
    width: 100%;
    margin-right: 1rem;
  }

  .footerbottom {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .footer {
    margin-top: 4rem;
  }

  .footerimg img {
    height: 3rem;
    margin-top: -2rem;
    padding: .5rem;
  }

  .footer-about h2,
  .footer-links h2,
  .adress h2 {
    font-size: 1.2rem;
  }

  .footer-about p,
  .adress p {
    font-size: .9rem;
  }

  .footer-links li a {
    font-size: .9rem;
  }

  .socialicons i {
    font-size: 1rem;
  }

  .footerbottom {
    font-size: 1rem;
  }
}

.hidden {
  display: none;
}

* {
  margin: 0;
  padding: 0;

}

.disclamer p {
  text-align: center;
}

.bankruptcyhead {
  margin: 2rem;
}

.bankruptcyhead h1 {
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #103c64;
}

.bankruptcyhead p {
  font-size: 1.5vw;
  text-align: center;
  margin-bottom: 8rem;
}

.bankruptcy {
  margin-top: 3rem;
  width: 100%;
  background-image: linear-gradient(to top, #95b7d6, #b5c7e1, #d2d8eb, #eaebf5, #ffffff);
  padding-bottom: 5rem;
}

.bankruptcycontent {
  margin-top: 3rem;
  margin-left: 3rem;
}

/* bankruptcy content 0 */
.bankruptcycontent0 {
  margin-bottom: 5rem;
  display: flex;
  width: 100%;
}

.bankruptcycontent0 .bankruptcydetail {
  width: 45%;
  box-shadow: 0 8px 12px -6px black;
  margin-left: 3rem;
  background-color: white;
  padding: 1rem;
}

.bankruptcycontent0 img {
  height: 30vw;
  border-radius: 5px;
}

.bankruptcycontent0 .bankruptcydetail h2 {
  font-size: 1.8vw;
  text-align: center;
}

.bankruptcycontent0 .bankruptcydetail p {
  margin-top: 1rem;
  font-size: 1.2vw;
  line-height: 1.7;
  text-align: justify;
}

/* bankruptcy content 1 */

.bankruptcycontent1 {
  margin-right: 4rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: row-reverse;
}

.bankruptcycontent1 .bankruptcydetail {
  box-shadow: 0 8px 12px -6px black;
  padding: 1rem;
  background-color: white;
  width: 45%;
  margin-right: 4rem;
}

.bankruptcycontent1 .bankruptcydetail h2 {
  margin-top: 2rem;
  font-size: 1.8vw;
  text-align: center;
}

.bankruptcycontent1 .bankruptcydetail p {
  font-size: 1.2vw;
  text-align: justify;
  margin-top: 1rem;
  line-height: 1.7;
}

.bankruptcycontent1 img {
  height: 30vw;
  border-radius: 5px;

}

/* bankruptcy content 2 */


.bankruptcycontent2 {
  margin-bottom: 5rem;
  display: flex;
  margin-left: 1rem;

}

.bankruptcycontent2 .bankruptcydetail {
  box-shadow: 0 8px 12px -6px black;
  padding: 1rem;
  background-color: white;
  width: 45%;
  margin-left: 4rem;
  position: relative;
}

.bankruptcycontent2 img {
  height: 30vw;
  border-radius: 5px;
}

.bankruptcycontent2 .bankruptcydetail h2 {
  margin-top: 2rem;
  text-align: justify;
  font-size: 1.8vw;
}

.bankruptcycontent2 .bankruptcydetail p {
  margin-top: 1rem;
  text-align: start;
  font-size: 1.2vw;
  line-height: 1.7;
}

/**/
.bankruptcycontent3 {
  margin-right: 5rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: row-reverse;
}

.bankruptcycontent3 .bankruptcydetail {
  box-shadow: 0 8px 12px -6px black;
  padding: 1rem;
  background-color: white;
  width: 45%;
  margin-right: 5rem;
}

.bankruptcycontent3 .bankruptcydetail h2 {
  margin-top: 2rem;
  font-size: 1.8vw;
  text-align: center;
}

.bankruptcycontent3 .bankruptcydetail p {
  font-size: 1.2vw;
  text-align: justify;
  margin-top: 1rem;
  line-height: 1.7;
}

.bankruptcycontent3 img {
  height: 30vw;
  border-radius: 5px;

}

.workcontent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.workitem {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  padding: 1%;
  background-color: #a6c5e338;
  box-shadow: 0 8px 12px -6px black;
  margin: 3%;
  margin-top: 3%;
  padding-bottom: 2rem;
  margin-top: 5rem;
}

.workitem img {
  height: 8rem;
  margin-bottom: 1rem;
}

.workitem p {
  font-size: 1.3rem;
  color: grey;
  margin-top: 1rem;
  text-align: center;
}

.workitem div {
  width: 100%;
}

.workitem .itempupper {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;

}

.workitem button {
  font-size: .9rem;
  padding: 3%;
  background-color: transparent;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 10%;
  outline: none;
}

.workitem button:hover {
  background-color: black;
}

.workitem button:hover a {
  color: white;
}

.workitem button a {
  color: black;
  text-decoration: none;
}


.workitem:hover {
  box-shadow: 0 8px 30px 0px #103c64;

  transform: scale(1.2);
  transition: .3s ease-in-out;
  border-bottom: 5px solid #103c64;
  border-top: 5px solid #103c64;
  border-radius: 40px;
}

#workupper {
  font-size: 3.5rem;
  color: #103c64;
  font-weight: 600;
  margin-top: 5%;
}

.checkoutmore {
  background-image: linear-gradient(to right top, #b9d5ff, #d0deff, #e3e8ff, #f3f3ff, #ffffff);
  width: 99%;
  margin-top: 1rem;
  height: 20rem;
  display: flex;
}

.checkouthead {
  font-size: 3.5vw;
  margin-top: 7rem;
  margin-left: 10rem;
}

.checkoutbtn button a {
  font-size: 1.6vw;
  border: none;
  text-decoration: none;
  color: white;
}

.checkoutbtn button {

  margin-top: 8rem;
  background-color: #103c64;
  padding: .5vw;
  border-radius: 40px;

}

.checkoutbtn button:hover {
  margin-top: 8rem;
  background-color: #103c64;
  padding: 1rem;
  border-radius: 40px;
}

.bankruptcydown {
  margin-top: 5rem;

}

.bankruptcydown h2 {
  font-size: 2vw;
  text-align: center;
}

.bankruptcydown p {
  margin: 3%;
  margin-top: 1rem;
  font-size: 1.5vw;
  text-align: center;
  text-align: justify;
}

.workdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#morework1 {
  background-color: transparent;
  padding: .5rem;
  transition: .3s ease;
}

#morework1 a {
  background-color: transparent;
  padding: .5rem;
  transition: .3s ease;
  font-size: 1.3rem;
}

#morework1 a {
  text-decoration: none;
  color: black;
}

#morework1:hover {
  background-color: black;
}

#morework1:hover a {
  color: white;
}



@media (width >=1800px) {

  .bankruptcycontent0 .bankruptcydetail {
    margin-top: 0rem;
  }

  .bankruptcycontent1 {
    margin-top: 2rem;
  }

  .bankruptcycontent1 .bankruptcydetail {
    margin-top: 0rem;
    width: 50rem;
    margin-right: 4rem;
  }

  .bankruptcycontent1 img {
    margin-right: 2rem;
  }

  .bankruptcycontent2 {
    margin-top: 2rem;
    margin-left: 2rem;
  }

  .bankruptcycontent2 .bankruptcydetail {
    margin-top: 0rem;
    width: 50rem;
    margin-left: 6rem;
  }

  .bankruptcycontent3 {
    margin-top: 2rem;
    margin-left: 4rem;
  }

  .bankruptcycontent3 .bankruptcydetail {
    margin-top: 0rem;
    width: 50rem;
    margin-right: 8rem;
  }

  .bankruptcycontent3 img {
    margin-right: 3rem;
  }

}




@media (max-width : 1800px) {
.bankruptcycontent0 .bankruptcydetail {
  margin-left: 1rem;
}
.bankruptcycontent2 .bankruptcydetail {
  margin-left: 2rem;
}
.bankruptcycontent2  {
  margin-left: 0rem;
}
.bankruptcycontent3  {
  margin-left: 3rem;
}
.bankruptcycontent3  img{
  margin-right: 1rem;
}
.bankruptcycontent3  .bankruptcydetail{

  margin-right: 3rem;
}
.bankruptcycontent3  {

  margin-right: 3rem;
}
}



@media (max-width : 1170px) {
  .workcontent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
  }
  .workitem{
    width: 18rem;
  }
  .workitem:hover{
    transform: scale(1.1);
  }
  .bankruptcycontent {
    margin-left: 1%;
  }

  .bankruptcycontent0 img {
    margin-top: 5%;
  }

  .bankruptcycontent1 {
    margin-left: 0%;
  }

  .bankruptcycontent1 img {
    margin-top: 10%;
  }

  .bankruptcycontent3 {
    margin-left: 1%;
  }

  .bankruptcycontent3 img {
    margin-top: 10%;
  }

  .bankruptcycontent2 {
    margin-left: 0%;
  }

  .bankruptcycontent2 .bankruptcydetail {
    width: 31rem;
    margin-left: 4%;
    margin-right: 4%;
  }

  .bankruptcycontent2 img {
    margin-top: 8%;
  }

#morework1{
  margin-top: 2rem;
}
}

@media (max-width: 1024px) {
  .workdown {
    margin-left: 0rem;
  }
  .workitem{
    width: 15rem;
  }

  .bankruptcycontent2 .bankruptcydetail {
    width: 28rem;
  }

  .poshdown .trainigbenefits {
    margin-top: -3%;
  }
}


@media(max-width : 900px) {
  .workitem{
    width: 13rem;
  }
  .bankruptcyhead div {
    font-size: 3.5vw;
    margin-bottom: 1rem;
  }

  .bankruptcyhead p {
    font-size: 3vw;
    margin-bottom: 4rem;
  }

  .bankruptcycontent0 .bankruptcydetail {
    margin-left: 4%;
  }

  .bankruptcycontent0 .bankruptcydetail h2 {
    font-size: 3vw;
  }

  .bankruptcycontent0 .bankruptcydetail p {
    font-size: 1.8vw;
  }

  .bankruptcycontent1 .bankruptcydetail {
    /* margin-left: 1%; */
    margin-right: 2%;
  }

  .bankruptcycontent1 .bankruptcydetail h2 {
    font-size: 3vw;
  }

  .bankruptcycontent1 .bankruptcydetail p {
    font-size: 1.8vw;
  }

  .bankruptcycontent3 .bankruptcydetail {
    margin-left: 1%;
  }

  .bankruptcycontent3 .bankruptcydetail h2 {
    font-size: 3vw;
  }

  .bankruptcycontent {
    margin-left: 2rem;
  }

  .bankruptcycontent3 .bankruptcydetail p {
    font-size: 1.8vw;
  }

  .bankruptcycontent2 .bankruptcydetail {
    width: 25rem;
    margin-right: 1rem;
  }

  .bankruptcycontent2 .bankruptcydetail h2 {
    font-size: 3vw;
  }

  .bankruptcycontent2 .bankruptcydetail p {
    text-align: justify;
    font-size: 1.8vw;
  }

  .workitem img {
    height: 5rem;

  }

  .workitem p {
    margin-top: 1rem;
    font-size: 2vw;
  }

  .workitem button {
    margin-top: 1rem;
    font-size: 2vw;
    padding: .1rem;
  }

  .poshdown {
    margin-top: 5%;
  }

  .poshdown .trainingvariety {
    margin-left: 5%;
  }

  .trainingvariety h2 {
    font-size: 2.2vw;

  }

  .trainingvariety li {
    font-size: 1.5vw;

  }

  .poshdown .trainigbenefits {
    margin-top: -2%;
  }

  .trainigbenefits h2 {
    font-size: 2.2vw;
  }

  .trainigbenefits li {
    font-size: 1.5vw;
  }

  .rbifemadown {
    margin: 5%;
  }

  .rbifemadown h2 {
    font-size: 3vw;
  }

  .rbifemadown p {
    font-size: 2vw;
  }

}

@media (max-width: 768px) {
  #workupper{
    font-size: 2.5rem;
  }
  .workcontent{
    display: grid;
    grid-template-columns: 1fr;

  }
  .workitem{
    width: 18rem;
    margin-bottom: 0rem;
    margin-top: 2rem;
  }
  .workitem:hover{
transform: scale(1);
  }


.bankruptcyhead h1{
  font-size: 2.5rem;
}
  .bankruptcycontent{
    margin-left: 0rem;
  }
  .bankruptcycontent0 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    margin-left: 0rem;
  }

  .bankruptcycontent1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;

  }

  .bankruptcycontent3 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .bankruptcycontent2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .bankruptcycontent0 .bankruptcydetail {
    padding: 0rem;
    margin-top: 0rem;
    margin-left: 0rem;
    background: transparent;
    box-shadow: none;
    width: 100%;
    margin: 2rem;
    margin-top: 1rem;
    padding: 0rem;
  }

  .bankruptcycontent0 img {
    height: 20rem;
    margin-left: 0rem;
  }

  .bankruptcycontent0 .bankruptcydetail p {
    font-size: 2.5vw;
    padding: 1rem;

  }

  .bankruptcycontent1 .bankruptcydetail {
    padding: 0rem;
    margin-top: 0rem;
    margin-left: 0rem;
    background: transparent;
    box-shadow: none;
    width: 100%;
    margin: 2rem;
    margin-top: 1rem;
    padding: 0rem;
    
  }

  .bankruptcycontent1 img {
    height: 20rem;
    margin-left: 0rem;
  }

  .bankruptcycontent1 .bankruptcydetail p {
    font-size: 2.5vw;
    padding: 1rem;
  }

  .bankruptcycontent1 .bankruptcydetail h2 {
    margin-top: 0rem;
  }

  .bankruptcycontent3 .bankruptcydetail {
    padding: 0rem;
    margin-top: 0rem;
    margin-left: 0rem;
    background: transparent;
    box-shadow: none;
    width: 100%;
    margin: 2rem;
    margin-top: 1rem;
    padding: 0rem;
  }

  .bankruptcycontent3 img {
    height: 20rem;
  }

  .bankruptcycontent3 .bankruptcydetail p {
    font-size: 2.5vw;
    padding: 1rem;

  }

  .bankruptcycontent3 .bankruptcydetail h2 {
    margin-top: 0rem;
  }

  .bankruptcycontent2 .bankruptcydetail h2 {
    margin-top: 0rem;
  }

  .bankruptcycontent2 .bankruptcydetail {
    padding: 0rem;
    margin-top: 0rem;
    margin-left: 0rem;
    background: transparent;
    box-shadow: none;
    width: 100%;
    margin: 2rem;
    margin-top: 1rem;
    padding: 0rem;
  }

  .bankruptcycontent2 img {
    height: 20rem;
    margin-left: 0rem;
  }

  .bankruptcycontent2 .bankruptcydetail p {
    font-size: 2.5vw;
    padding: 1rem;

  }
  .bankruptcycontent2 .bankruptcydetail h2 {
   text-align: center;

  }

  .workitem img {
    height: 5rem;

  }

  .workitem p {
    margin-top: 1rem;
    font-size: 1.3rem;
  }

  .workitem .itempupper{
    margin-top: 1rem;
    font-size: 1.5rem;
  }

  .workitem button {
    margin-top: 1rem;
    font-size: 1.2rem;
    padding: .3rem;
  }

  #morework1 {
    outline: none;
    border: 1px solid black;
    margin-top: 3rem;
    margin-left: 2rem;
  }

  
}




@media (max-width: 546px) {
  #workupper{
    font-size: 2rem;
  }
  .bankruptcyhead p{
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  .bankruptcyhead h1{
    font-size: 2rem;
  }
  .bankruptcycontent0 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    margin-left: -1rem;
  }

  .bankruptcycontent1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  .bankruptcycontent3 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  .bankruptcycontent2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-left: 0rem;
  }

  
  .bankruptcycontent0 img {
    height: 15rem;
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
  .bankruptcycontent0 .bankruptcydetail {
    margin-top: 0rem;
margin-left: 3rem;
margin-right: 1rem;
    background: transparent;
    box-shadow: none;
    width: 100%;
  }

  .bankruptcycontent0 .bankruptcydetail p {
    font-size: 1rem;
margin-top: 0rem;
  }
  .bankruptcycontent0 .bankruptcydetail h2 {
    font-size: 1.2rem;
margin-top: 0rem;
  }

  .bankruptcycontent1 .bankruptcydetail {
    margin-top: 0rem;
margin-left: 0rem;
    box-shadow: none;
    background: transparent;
    width:100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .bankruptcycontent1 img {
    height: 15rem;
  }

  .bankruptcycontent1 .bankruptcydetail p {
    font-size: 1rem;
    margin-top: 0rem;
  }

  .bankruptcycontent1 .bankruptcydetail h2 {
    font-size: 1.2rem;
    margin-top: 0rem;
  }

  .bankruptcycontent3 .bankruptcydetail {
    margin-top: 0rem;

    margin-left: 0rem;
    box-shadow: none;
    background: transparent;
    width: 100%;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .bankruptcycontent3 img {
    height: 13rem;
  }

  .bankruptcycontent3 .bankruptcydetail p {
    font-size: 1rem;
margin-top: 0rem;
  }

  .bankruptcycontent3 .bankruptcydetail h2 {
    font-size: 1.2rem;
margin-top: 0rem;
  }

  .bankruptcycontent2 .bankruptcydetail h2 {
    font-size: 1.2rem;
    margin-top: 0rem;
  }

  .bankruptcycontent2 .bankruptcydetail {
    box-shadow: none;
    background: transparent;
    width: 100%;
    box-shadow: none;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .bankruptcycontent2 img {
    height: 15rem;
  }

  .bankruptcycontent2 .bankruptcydetail p {
    font-size: 1rem;
  }
.workitem{
  width: 15rem;
}

  .workitem p{
    font-size: 1.2rem;
  }
  .workitem .itempupper{
    font-size: 1rem;
  }
  #morework1 a{
    font-size: 1rem;
  }
 
}

@media (max-width: 425px) {
.workcontent{
  margin-left: -1.5rem;
}
}

@media (min-width : 0) and (max-width: 350px) {
  #morework1 a{
    font-size: 1rem;
    margin-left: 0rem;
  }
  #morework1 {
    margin-left: 0rem;
  }
  .bankruptcyhead h1{
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .bankruptcyhead p{
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  .bankruptcycontent0 img {
    height: 8rem;
    width: 13rem;
    margin-left: 2rem;
  }


  .bankruptcycontent1 img {
    width: 13rem;
    height: 8rem;
  }


  .bankruptcycontent2 img {
    width: 13rem;
    height: 8rem;
  }

  .bankruptcycontent2 .bankruptcydetail {
    width: 100%;
  }

  .bankruptcycontent3 img {
    height: 8rem;
    width: 13rem;
  }

  .bankruptcycontent3 .bankruptcydetail {
    width: 100%;
  }

  .bankruptcycontent4 img {
    width: 20rem;
    height: 15rem;
  }

  .bankruptcycontent4 .bankruptcydetail {
    width: 100%;
  }
}